import React from 'react';
import BasicTemplate from '@templates/BasicTemplate';
import styled, { css } from 'styled-components';
import Headline from '@components/atoms/Headline/Headline';
import Paragraph from '@components/atoms/Paragraph/Paragraph';

interface StyledProps {
  $isMainHeading?: boolean;
}

const SectionWrapper = styled.section`
  max-width: 1250px;
  margin: 0 auto;
  padding: 100px 15px 500px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  a {
    color: ${({ theme }) => theme.primary};
  }
`;

const StyledHeadline = styled(Headline)<StyledProps>`
  margin: 30px 0;
  ${({ $isMainHeading }) =>
    $isMainHeading &&
    css`
      text-align: center;
      margin: 100px 0;
    `}
`;

const StyledList = styled.ul`
  list-style-type: none;
`;
const ListItem = styled.li``;

const PolitykaPrywatnosci = () => {
  return (
    <BasicTemplate>
      <SectionWrapper>
        <StyledHeadline as="h2" $isHeadline2 $isMainColor $isMainHeading>
          Polityka prywatności
        </StyledHeadline>
        <StyledHeadline as="h3" $isMainColor $isHeadline2>
          1. Informacje ogólne
        </StyledHeadline>
        <Paragraph>
          Niniejsza polityka dotyczy Serwisu www,funkcjonującego pod adresem
          url: ekowkra.pl <br /> Operatorem serwisu oraz Administratorem danych
          osobowych jest: BBE Inwestycje Hoża 41 / 8E,00-681 Warszawa <br />{' '}
          Adres kontaktowy poczty elektronicznej operatora: biuro@ekowkra.pl
          <br />
          Operator jest Administratorem Twoich danych osobowych w odniesieniu do
          danych podanych dobrowolnie w Serwisie. <br /> Serwis wykorzystuje
          dane osobowe w następujących celach:
        </Paragraph>
        <StyledList>
          <ListItem>- Prowadzenie newslettera</ListItem>
          <ListItem>- Prowadzenie rozmów typu chat online</ListItem>
          <ListItem>- Obsługa zapytań przez formularz</ListItem>
          <ListItem>- Prezentacja oferty lub informacji</ListItem>
        </StyledList>
        <Paragraph>
          Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich
          zachowaniu w następujący sposób: <br />
          Poprzez dobrowolnie wprowadzone w formularzach dane, które zostają
          wprowadzone do systemów Operatora. <br />
          Poprzez zapisywanie w urządzeniach końcowych plików cookie
          (tzw.„ciasteczka”).
        </Paragraph>

        <StyledHeadline as="h3" $isMainColor $isHeadline2>
          2.Wybrane metody ochrony danych stosowane przez Operatora
        </StyledHeadline>
        <Paragraph>
          Miejsca logowania i wprowadzania danych osobowych są chronione w
          warstwie transmisji (certyfikat SSL). Dzięki temu dane osobowe i dane
          logowania,wprowadzone na stronie, zostają zaszyfrowane w komputerze
          użytkownika i mogą być odczytane jedynie na docelowym serwerze.
          Operator okresowo zmienia swoje hasła administracyjne.
        </Paragraph>

        <StyledHeadline as="h3" $isMainColor $isHeadline2>
          3. Hosting
        </StyledHeadline>
        <Paragraph>
          Serwis jest hostowany (technicznie utrzymywany) na serwera operatora:
          cyberFolks.pl
        </Paragraph>

        <StyledHeadline as="h3" $isMainColor $isHeadline2>
          4. Twoje prawa i dodatkowe informacje o sposobie wykorzystania danych
        </StyledHeadline>
        <Paragraph>
          W niektórych sytuacjach Administrator ma prawo przekazywać Twoje dane
          osobowe innym odbiorcom, jeśli będzie to niezbędne do wykonania
          zawartej z Tobą umowy lub do zrealizowania obowiązków ciążących na
          Administratorze. Dotyczy to takich grup odbiorców: <br />
          upoważnieni pracownicy i współpracownicy, którzy korzystają z danych w
          celu realizacji celu działania strony <br />
          firmy świadczące usługi marketingu na rzecz Administratora <br />
          Twoje dane osobowe przetwarzane przez Administratora nie dłużej, niż
          jest to konieczne do wykonania związanych z nimi czynności określonych
          osobnymi przepisami (np. o prowadzeniu rachunkowości). <br /> W
          odniesieniu do danych marketingowych dane nie będą przetwarzane dłużej
          niż przez 3 lata.
          <br />
          Przysługuje Ci prawo żądania od Administratora:
        </Paragraph>
        <StyledList>
          <ListItem>- dostępu do danych osobowych Ciebie dotyczących,</ListItem>
          <ListItem>- ich sprostowania,</ListItem>
          <ListItem>- usunięcia,</ListItem>
          <ListItem>- ograniczenia przetwarzania,</ListItem>
          <ListItem>- oraz przenoszenia danych.</ListItem>
        </StyledList>
        <Paragraph>
          Przysługuje Ci prawo do złożenia sprzeciwu w zakresie przetwarzania
          wskazanego w pkt 3.3 c{')'} wobec przetwarzania danych osobowych w
          celu wykonania prawnie uzasadnionych interesów realizowanych przez
          Administratora,w tym profilowania, przy czym prawo sprzeciwu nie
          będzie mogło być wykonane w przypadku istnienia ważnych prawnie
          uzasadnionych podstaw do przetwarzania, nadrzędnych wobec Ciebie
          interesów, praw i wolności,w szczególności ustalenia, dochodzenia lub
          obrony roszczeń. <br />
          Na działania Administratora przysługuje skarga do Prezesa Urzędu
          Ochrony Danych Osobowych, ul. Stawki 2,00-193 Warszawa <br />
          Podanie danych osobowych jest dobrowolne, lecz niezbędne do obsługi
          Serwisu. <br />
          W stosunku do Ciebie mogą być podejmowane czynności polegające na
          zautomatyzowanym podejmowaniu decyzji,w tym profilowaniu w celu
          świadczenia usług w ramach zawartej umowy oraz w celu prowadzenia
          przez Administratora marketingu bezpośredniego. <br />
          Dane osobowe nie są przekazywane od krajów trzecich w rozumieniu
          przepisów o ochronie danych osobowych. Oznacza to, że nie przesyłamy
          ich poza teren Unii Europejskiej.
        </Paragraph>

        <StyledHeadline as="h3" $isMainColor $isHeadline2>
          5. Informacje w formularzach
        </StyledHeadline>
        <Paragraph>
          Serwis zbiera informacje podane dobrowolnie przez użytkownika,w tym
          dane osobowe, o ile zostaną one podane.
          <br />
          Serwis może zapisać informacje o parametrach połączenia (oznaczenie
          czasu, adres IP). <br />
          Serwis,w niektórych wypadkach,może zapisać informację ułatwiającą
          powiązanie danych w formularzu z adresem e-mail użytkownika
          wypełniającego formularz.W takim wypadku adres e-mail użytkownika
          pojawia się wewnątrz adresu url strony zawierającej formularz. <br />
          Dane podane w formularzu są przetwarzane w celu wynikającym z funkcji
          konkretnego formularza, np.w celu dokonania procesu obsługi zgłoszenia
          serwisowego lub kontaktu handlowego,rejestracji usług itp. Każdorazowo
          kontekst i opis formularza w czytelny sposób informuje, do czego on
          służy.
        </Paragraph>

        <StyledHeadline as="h3" $isMainColor $isHeadline2>
          6. Logi Administratora
        </StyledHeadline>
        <Paragraph>
          Informacje zachowaniu użytkowników w serwisie mogą podlegać logowaniu.
          Dane te są wykorzystywane w celu administrowania serwisem.
        </Paragraph>

        <StyledHeadline as="h3" $isMainColor $isHeadline2>
          7. Istotne techniki marketingowe
        </StyledHeadline>
        <Paragraph>
          Operator stosuje analizę statystyczną ruchu na stronie, poprzez Google
          Analytics (Google Inc. z siedzibą w USA). Operator nie przekazuje do
          operatora tej usługi danych osobowych, a jedynie zanonimizowane
          informacje.Usługa bazuje na wykorzystaniu ciasteczek w urządzeniu
          końcowym użytkownika.W zakresie informacji o preferencjach użytkownika
          gromadzonych przez sieć reklamową Google użytkownik może przeglądać i
          edytować informacje wynikające z plików cookies przy pomocy narzędzia:{' '}
          <a href="https://www.google.com/ads/preferences/">
            https://www.google.com/ads/preferences/
          </a>{' '}
          <br />
          Operator stosuje techniki remarketingowe, pozwalające na dopasowanie
          przekazów reklamowych do zachowania użytkownika na stronie, co może
          dawać złudzenie, że dane osobowe użytkownika są wykorzystywane do jego
          śledzenia, jednak w praktyce nie dochodzi do przekazania żadnych
          danych osobowych od Operatora do operatorom reklam. Technologicznym
          warunkiem takich działań jest włączona obsługa plików cookie. <br />
          Operator stosuje korzysta z piksela Facebooka. Ta technologia
          powoduje, że serwis Facebook (Facebook Inc. z siedzibą w USA) wie, że
          dana osoba w nim zarejestrowana korzysta z Serwisu. Bazuje w tym
          wypadku na danych,wobec których sam jest administratorem, Operator nie
          przekazuje od siebie żadnych dodatkowych danych osobowych serwisowi
          Facebook.Usługa bazuje na wykorzystaniu ciasteczek w urządzeniu
          końcowym użytkownika.
          <br />
          Operator stosuje rozwiązanie badające zachowanie użytkowników poprzez
          tworzenie map ciepła oraz nagrywanie zachowania na stronie. Te
          informacje są anonimizowane zanim zostaną przesłane do operatora
          usługi tak, że nie wie on jakiej osoby fizycznej one dotyczą.W
          szczególności nagrywaniu nie podlegają wpisywane hasła oraz inne dane
          osobowe.
          <br />
          Operator stosuje rozwiązanie automatyzujące działanie Serwisu w
          odniesieniu do użytkowników, np.mogące przesłać maila do użytkownika
          po odwiedzeniu konkretnej podstrony, o ile wyraził on zgodę na
          otrzymywanie korespondencji handlowej od Operatora.
        </Paragraph>

        <StyledHeadline as="h3" $isMainColor $isHeadline2>
          8. Informacja o plikach cookies
        </StyledHeadline>
        <Paragraph>
          Serwis korzysta z plików cookies.
          <br />
          Pliki cookies (tzw.„ciasteczka”) stanowią dane informatyczne,w
          szczególności pliki tekstowe, które przechowywane są w urządzeniu
          końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron
          internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony
          internetowej, z której pochodzą, czas przechowywania ich na urządzeniu
          końcowym oraz unikalny Podmiotem zamieszczającym na urządzeniu
          końcowym Użytkownika Serwisu pliki cookies oraz uzyskującym do nich
          dostęp jest operator Serwisu.
          <br />
          Pliki cookies wykorzystywane są w następujących celach:
          <br />
          utrzymanie sesji użytkownika Serwisu (po zalogowaniu), dzięki której
          użytkownik nie musi na każdej podstronie Serwisu ponownie wpisywać
          loginu i hasła;
          <br />
          realizacji celów określonych powyżej w części „Istotne techniki
          marketingowe”;
          <br />
          W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies:
          „sesyjne” (session cookies) oraz „stałe” (persistent cookies). Cookies
          „sesyjne” są plikami tymczasowymi, które przechowywane są w urządzeniu
          końcowym Użytkownika do czasu wylogowania, opuszczenia strony
          internetowej lub wyłączenia oprogramowania (przeglądarki
          internetowej).„Stałe” pliki cookies przechowywane są w urządzeniu
          końcowym Użytkownika przez czas określony w parametrach plików cookies
          lub do czasu ich usunięcia przez Użytkownika.
          <br />
          Oprogramowanie do przeglądania stron internetowych (przeglądarka
          internetowa) zazwyczaj domyślnie dopuszcza przechowywanie plików
          cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą
          dokonać zmiany ustawień w tym zakresie. Przeglądarka internetowa
          umożliwia usunięcie plików cookies. Możliwe jest także automatyczne
          blokowanie plików cookies Szczegółowe informacje na ten temat zawiera
          pomoc lub dokumentacja przeglądarki internetowej.
          <br />
          Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre
          funkcjonalności dostępne na stronach internetowych Serwisu.
          <br />
          Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu
          wykorzystywane mogą być również przez współpracujące z operatorem
          Serwisu podmioty,w szczególności dotyczy to firm: Google (Google Inc.
          z siedzibą w USA), Facebook (Facebook Inc. z siedzibą w USA), Twitter
          (Twitter Inc. z siedzibą w USA).
        </Paragraph>

        <StyledHeadline as="h3" $isMainColor $isHeadline2>
          9.Zarządzanie plikami cookies – jak w praktyce wyrażać i cofać zgodę?
        </StyledHeadline>
        <Paragraph>
          Jeśli użytkownik nie chce otrzymywać plików cookies,może zmienić
          ustawienia przeglądarki.Zastrzegamy, że wyłączenie obsługi plików
          cookies niezbędnych dla procesów uwierzytelniania, bezpieczeństwa,
          utrzymania preferencji użytkownika może utrudnić, a w skrajnych
          przypadkach może uniemożliwić korzystanie ze stron www
          <br />W celu zarządzania ustawieniami cookies wybierz z listy poniżej
          przeglądarkę internetową, której używasz i postępuj zgodnie z
          instrukcjami:
        </Paragraph>
        <StyledList>
          <ListItem>
            <a href="https://support.microsoft.com/pl-pl/windows/usuwanie-plik%C3%B3w-cookie-i-zarz%C4%85dzanie-nimi-168dab11-0753-043d-7c16-ede5947fc64d">
              - Edge
            </a>
          </ListItem>
          <ListItem>
            <a href="https://support.microsoft.com/pl-pl/topic/jak-usun%C4%85%C4%87-pliki-cookie-w-programie-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc">
              - Internet Explorer
            </a>
          </ListItem>
          <ListItem>
            <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pl">
              - Chrome
            </a>
          </ListItem>
          <ListItem>
            <a href="https://support.apple.com/pl-pl/HT201265">- Safari</a>
          </ListItem>
          <ListItem>
            <a href="https://support.mozilla.org/pl/kb/ciasteczka#w_ustawienia-ciasteczek">
              - Firefox
            </a>
          </ListItem>
          <ListItem>
            <a href="https://help.opera.com/pl/latest/web-preferences/">
              - Opera
            </a>
          </ListItem>
        </StyledList>
        <Paragraph>Urządzenia mobilne:</Paragraph>
        <StyledList>
          <ListItem>
            <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DAndroid&hl=pl&oco=0">
              - Android
            </a>
          </ListItem>
          <ListItem>
            <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DiOS&hl=pl">
              - Safari (iOS)
            </a>
          </ListItem>
          <ListItem>
            <a href="https://support.microsoft.com/pl-pl/windows/usuwanie-plik%C3%B3w-cookie-i-zarz%C4%85dzanie-nimi-168dab11-0753-043d-7c16-ede5947fc64d">
              - Windows Phone
            </a>
          </ListItem>
        </StyledList>
      </SectionWrapper>
    </BasicTemplate>
  );
};

export default PolitykaPrywatnosci;
